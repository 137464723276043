<template>
    <v-app>
      <modal></modal>
      <v-content>
        <main-layout/>
      </v-content>
    </v-app>
</template>

<script>
import layout from '@/components/layout'
import modal from '@/elements/modal.vue'
import { getAlternatesForCurrentLanguage, getCanonicals } from '@/helpers/locale-helper'

export default {
  name: 'App',
  metaInfo() {
    return {
      title: this.$t('meta_title-main'),
      keywords: this.$t('meta_keywords'),
      description: this.$t('meta_description'),
      link: getAlternatesForCurrentLanguage().concat(getCanonicals())
    }
  },
  methods: {
  },
  components: {
    'main-layout': layout,
    'modal': modal
  },
  data: () => ({
  }),
};
</script>
